<template>
  <CContainer
    class="pass-history-view appointment-pass-view datatable-container mb-5"
  >
    <Loader :is-processing="state.isLoading || areAppointmentPassesLoading" />
    <CRow class="mt-0 mt-sm-4">
      <CCol md="12">
        <div class="table-tabs-header bg-white mb-2 p-0">
          <div
            :class="{ 'show-hidden-tabs': state.showTabs }"
            class="d-flex align-items-center justify-content-between flex-wrap"
          >
            <DashboardTabs />
            <div
              class="d-none d-sm-flex actions align-items-center pt-0 p-sm-2"
            >
              <CButton
                v-show="isActiveDashboardUpdate"
                class="py-2 px-2 me-4 btn btn-inactive btn-sockets"
                @click="
                  $store.commit('dashboardTable/SET_DASHBOARD_UPDATES', false)
                "
              >
                <i class="fi flaticon-pause me-2"></i>Pause Dashboard
              </CButton>
              <CButton
                v-show="!isActiveDashboardUpdate"
                class="py-2 px-2 me-4 btn bg-gradient-red text-white btn-sockets"
                @click="resumeDashboardUpdate"
              >
                <i class="fi flaticon-play me-2"></i>Resume Dashboard
              </CButton>
              <CLink
                class="lh-normal text-accent-blue-light"
                @click="exportAsCSV()"
              >
                <i class="fi flaticon-download me-1"></i>
                <span class="d-none d-md-inline-block">Download CSV</span>
              </CLink>
              <div
                class="d-flex align-items-center search-field mb-0 ms-2 ms-md-4"
              >
                <CInputGroup class="w-auto">
                  <div class="input-group-prepend">
                    <span class="input-group-text lh-normal"
                      ><div><i class="flaticon-magnifying-glass"></i></div
                    ></span>
                  </div>
                  <CFormInput
                    class=""
                    placeholder="Search"
                    :value="filterData.search_query"
                    :disabled="state.isLoading"
                    @update:model-value="searchByQuery"
                  >
                  </CFormInput>
                </CInputGroup>
                <CButton
                  v-if="!isAppoinmentMode"
                  class="btn-advanced-search d-flex align-items-center"
                  @click="
                    state.isVisibleAdvancedSearch =
                      !state.isVisibleAdvancedSearch
                  "
                >
                  <i
                    class="fi me-1"
                    :class="
                      state.isVisibleAdvancedSearch
                        ? 'flaticon-minus'
                        : 'flaticon-plus'
                    "
                  ></i
                  >Advanced Search
                </CButton>
              </div>
            </div>
            <div
              class="m-auto p-2 d-block d-sm-none cursor-pointer"
              @click="state.showTabs = !state.showTabs"
            >
              {{ !state.showTabs ? "Show more" : "Show less" }}
            </div>
          </div>
          <div
            v-if="!isAppoinmentMode"
            v-show="state.isVisibleAdvancedSearch"
            class="advanced-search-container px-4 pb-3"
          >
            <CRow class="justify-content-center mt-4">
              <CCol md="4">
                <LazyLoadSelect
                  :is-multiple="true"
                  classes="mb-3"
                  :selected="filterData.by_students"
                  type="student"
                  placeholder="Search students"
                  @changed="
                    (value) => {
                      $store.commit('dashboardTable/UPDATE_FILTERS_DATA', {
                        filter: 'by_students',
                        value: value
                      })
                    }
                  "
                />
              </CCol>
              <CCol md="4">
                <LazyLoadSelect
                  :is-multiple="true"
                  classes="mb-3"
                  :selected="filterData.by_teachers"
                  type="adults"
                  placeholder="Search teachers"
                  @changed="
                    (value) => {
                      $store.commit('dashboardTable/UPDATE_FILTERS_DATA', {
                        filter: 'by_teachers',
                        value: value
                      })
                    }
                  "
                />
              </CCol>
              <CCol md="4">
                <LazyLoadSelect
                  :is-multiple="true"
                  :is-processing="false"
                  classes="mb-3"
                  :selected="filterData.by_rooms"
                  type="locations"
                  placeholder="Search rooms"
                  @changed="
                    (value) => {
                      $store.commit('dashboardTable/UPDATE_FILTERS_DATA', {
                        filter: 'by_rooms',
                        value: value
                      })
                    }
                  "
                />
              </CCol>
              <CCol md="12" class="mt-1 text-center text-sm-start">
                <div
                  class="d-inline-block text-center me-2"
                  @click="advancedFilter()"
                >
                  <CButton class="btn bg-gradient-blue search-btn text-white">
                    <div class="p-small">Search</div>
                  </CButton>
                </div>
                <div class="d-inline-block text-center">
                  <CButton class="btn me-2 clear-btn" @click="clearFilter()">
                    Clear
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </div>
        </div>
        <div
          v-if="!isAppoinmentMode"
          class="status-guides-container mb-2 flex-wrap"
        >
          <DashboardStatusFilters />
          <DashboardTimeFilters />
          <div
            class="d-flex flex-column justify-content-center p-2 order-2 order-lg-2"
          >
            <div class="text-center mb-1">Basic View</div>
            <div class="d-flex align-items-center">
              <label for="basicViewSwitch" class="me-2">Off</label>
              <CFormSwitch
                id="basicViewSwitch"
                switch-type="custom"
                class="cs-switch mx-1"
                shape="pill"
                :checked="state.isBasicView"
                @change="state.isBasicView = !state.isBasicView"
              />
              <label for="basicViewSwitch">On</label>
            </div>
          </div>
        </div>

        <DataTableOptimized
          v-if="!state.isLoading && !isAppoinmentMode"
          id="dashboardTable"
          class="datatable bg-white"
          :class="{ 'basic-view': state.isBasicView }"
          add-table-classes="datatable-table dashboard-table"
          :items="dashboardPasses"
          :fields="activeDataTableFields"
          :sorter="{ external: true }"
          :no-items-view="{
            noResults: 'No items available',
            noItems: 'No items available'
          }"
          :basic-view="state.isBasicView"
          @update:sorter-value="sortByColumn"
          @row-clicked="setActiveRow"
        >
          <template #over-table>
            <HelpCenterButton
              classes="top-right mt-2 me-2"
              width="15"
              heigth="15"
              content-key="dashboard_datatable"
              :is-old="true"
            />
          </template>
          <template #student_sis_id="{ item, styles }">
            <div class="pass-time-info" :style="styles">
              {{
                item.user && item.user.student_sis_id
                  ? item.user.student_sis_id
                  : ""
              }}
            </div>
          </template>
          <template #user="{ item, styles }">
            <UserColumnData
              :pass="item"
              :is-basic-view="
                state.isBasicView && state.activeTableRow.item.id !== item.id
              "
              :style="styles"
            />
          </template>

          <template #badge_flags="{ item, styles }">
            <div class="status-field" :style="styles">
              <div
                v-if="getPassStatus(item)"
                :style="`color:${
                  getPassStatus(item).color ? getPassStatus(item).color : ''
                }`"
              >
                {{ getPassStatus(item).label }}
              </div>
            </div>
          </template>

          <template #approved_at="{ item, styles }">
            <div class="pass-time-info align-top" :style="styles">
              <b>{{ item.from ? item.from.name : "" }}</b>
              <div
                v-show="
                  !state.isBasicView || state.activeTableRow.item.id === item.id
                "
              >
                <p class="my-1 mx-0 p-0">
                  {{
                    item.approved_at
                      ? $helpers.transformDate(item.approved_at, "h:mm A")
                      : ""
                  }}
                </p>
                <InOutColumn :pass="item" section="approved_at" />
                <PassActionButtons :pass="item" :actions-status="1" />
              </div>
            </div>
          </template>

          <template #completed_at="{ item, styles }">
            <div class="pass-time-info in-time align-top" :style="styles">
              <b>{{ item.to ? item.to.name : "" }}</b>
              <div
                v-show="
                  !state.isBasicView || state.activeTableRow.item.id === item.id
                "
              >
                <p class="my-1 mx-0 p-0">
                  {{
                    item.completed_at
                      ? $helpers.transformDate(item.completed_at, "h:mm A")
                      : ""
                  }}
                </p>

                <InOutColumn :pass="item" section="completed_at" />

                <PassActionButtons :pass="item" :actions-status="2" />
              </div>
            </div>
          </template>

          <template #approved_by="{ item, styles }">
            <div class="pass-time-info align-top" :style="styles">
              <b>{{
                item.child && item.child.from ? item.child.from.name : ""
              }}</b>
              <div
                v-show="
                  !state.isBasicView || state.activeTableRow.item.id === item.id
                "
              >
                <p class="my-1 mx-0 p-0">
                  {{
                    item.child && item.child.approved_at
                      ? $helpers.transformDate(item.child.approved_at, "h:mm A")
                      : ""
                  }}
                </p>

                <InOutColumn :pass="item" section="child_approved_at" />
                <PassActionButtons :pass="item" :actions-status="3" />
              </div>
            </div>
          </template>

          <template #completed_by="{ item, styles }">
            <div class="pass-time-info in-time align-top" :style="styles">
              <b>{{ item.child && item.child.to ? item.child.to.name : "" }}</b>
              <div
                v-show="
                  !state.isBasicView || state.activeTableRow.item.id === item.id
                "
              >
                <p class="my-1 mx-0 p-0">
                  {{
                    item.child && item.child.completed_at
                      ? $helpers.transformDate(
                          item.child.completed_at,
                          "h:mm A"
                        )
                      : ""
                  }}
                </p>

                <InOutColumn :pass="item" section="child_completed_at" />

                <PassActionButtons :pass="item" :actions-status="4" />
              </div>
            </div>
          </template>

          <template #total_time="{ item, styles }">
            <div class="pass-time-info in-time align-top" :style="styles">
              <b>{{
                (item.total_time && item.badge_flags.ended) ||
                item.badge_flags.system_completed
                  ? item.total_time
                  : ""
              }}</b>
            </div>
          </template>

          <template #comments="{ item, styles }">
            <PassComments
              :pass="item"
              :is-basic-view="
                state.isBasicView && state.activeTableRow.item.id !== item.id
              "
              commentable-type="App\Models\Pass"
              :style="styles"
            />
          </template>

          <template #expired_at="{ item, styles }">
            <div :style="styles">
              {{
                item.expired_at ? $helpers.transformDate(item.expired_at) : ""
              }}
            </div>
          </template>
        </DataTableOptimized>
        <DashboardDataTableAptV6 v-if="isAppoinmentMode" ref="aptPassTable" />
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { reactive, computed, onUnmounted, onMounted, watchEffect } from "vue"
import { useStore } from "vuex"
import UserColumnData from "@/v3components/Datatables/ColumnComponents/UserColumnData.vue"
import PassComments from "@/v3components/Datatables/ColumnComponents/PassComments.vue"
import PassActionButtons from "@/v3components/shared/PassComponents/PassActionButtons.vue"
import InOutColumn from "@/v3components/shared/PassComponents/InOutColumnOld.vue"
import LazyLoadSelect from "@/v3components/shared/Form/LazyLoadSelectOld.vue"
import DashboardTabs from "@/v3components/Datatables/DashboardDataTable/TabsAndFilter/DashboardTabsOld.vue"
import DashboardStatusFilters from "@/v3components/Datatables/DashboardDataTable/TabsAndFilter/DashboardStatusFiltersOld.vue"
import DashboardTimeFilters from "@/v3components/Datatables/DashboardDataTable/TabsAndFilter/DashboardTimeFiltersOld.vue"
import DashboardDataTableAptV6 from "@/v3components/Datatables/DashboardDataTable/DashboardDataTableAptV6Old.vue"
import HelpCenterButton from "@/v3components/HelpCenterButton.vue"
import Loader from "@/v3components/shared/Loader/Loader.vue"
import helpers from "@/helpers/index.js"
import download from "@/helpers/downloadCSV.js"
import tableConfig from "./dashboardTableConfigOld.js"

export default {
  name: "DashboardDataTableOld",
  components: {
    Loader,
    UserColumnData,
    PassComments,
    PassActionButtons,
    InOutColumn,
    LazyLoadSelect,
    HelpCenterButton,
    DashboardTabs,
    DashboardStatusFilters,
    DashboardTimeFilters,
    DashboardDataTableAptV6
  },
  setup() {
    const store = useStore()

    const state = reactive({
      ...tableConfig,
      isBasicView: false,
      isLazyLoadingMode: false,
      isVisibleAdvancedSearch: false,
      modal: {
        isVisible: {
          editApptPass: false
        }
      },
      activeTableRow: { item: { id: null }, index: null },
      isLoading: false,
      showTabs: false
    })

    const periods = computed(() => store.getters["periods/formListStudents"])
    const editablePass = computed(
      () => store.getters["adultAptPass/editablePass"]
    )
    const passesTimeSettings = computed(
      () => store.getters["passes/passesTimeSettings"]
    )
    const currentUser = computed(() => store.getters["authentication/user"])
    const filterData = computed(
      () => store.getters["dashboardTable/filterData"]
    )
    const aptFilterData = computed(
      () => store.getters["dashboardTable/aptFilterData"]
    )
    const dashboardPasses = computed(
      () => store.getters["dashboardTable/passes"]
    )
    const isActiveDashboardUpdate = computed(
      () => store.getters["dashboardTable/isActiveDashboardUpdate"]
    )
    const activeSchoolHasAppoitmentPasses = computed(
      () => store.getters["schools/activeSchoolHasAppoitmentPasses"]
    )
    const isUserInTransparency = computed(
      () => store.getters["authentication/isUserInTransparency"]
    )
    const stats = computed(() => store.getters["dashboardTable/stats"])
    const activePassType = computed(
      () => store.getters["dashboardTable/getType"]
    )
    const activePassState = computed(
      () => store.getters["dashboardTable/getStatus"]
    )
    const school = computed(() => store.getters["schools/activeSchool"])
    const areAppointmentPassesLoading = computed(
      () => store.getters["adultAptPass/areAppointmentPassesLoading"]
    )

    const isAppoinmentMode = computed(() => {
      return activePassType.value.includes("appointment")
    })

    const activeDataTableFields = computed(() => {
      return state.dataTableFields
        .filter((field) =>
          !state.isBasicView ? field.key !== "badge_flags" : true
        )
        .filter((field) =>
          !filterData.value.student_sis_id
            ? field.key !== "student_sis_id"
            : field
        )
    })

    onUnmounted(() => {
      document.title = "securlypass"
    })

    onMounted(async () => {
      state.isLoading = true
      await store.dispatch("rooms/getAutoPassLimit")
      /**
       * Removed the below code as the data is already fetched in the view
       * File - src/v3views/DashboardOld.vue
       */
      // store.dispatch("dashboardTable/getDashboard")
      if (!passesTimeSettings.value) {
        await store.dispatch("passes/getPassesTimeSetting")
      }
      state.isLoading = false
    })

    watchEffect(async () => {
      const type = activePassType.value
      if (
        [
          "appointments_today",
          "appointments_awaiting",
          "appointments_future_awaiting"
        ].includes(type)
      ) {
        return
      }
      const status = activePassState.value
      state.isLoading = true
      await store.dispatch("dashboardTable/getDashboardPassesData", {
        type,
        status
      })
      state.isLoading = false
    })

    const exportAsCSV = () => {
      if (isAppoinmentMode.value) {
        state.isLoading = true
        store
          .dispatch("adultAptPass/getCSVAppointmentPasses", {
            student_sis_id: aptFilterData.value.student_sis_id
          })
          .then((response) => {
            if (response.data) {
              download.CSVExport(response.data, "appointment-passes")
            }
            state.isLoading = false
          })
          .catch(() => {
            state.isLoading = false
          })
      } else {
        state.isLoading = true
        store
          .dispatch("dashboardTable/getDashboardPassesExport")
          .then((response) => {
            if (response.data) {
              download.CSVExport(response.data, "passes")
            }
            state.isLoading = false
          })
          .catch(() => {
            state.isLoading = false
          })
      }
    }

    const clearFilter = () => {
      store.commit("dashboardTable/UPDATE_FILTERS_DATA", [
        {
          filter: "by_students",
          value: []
        }
      ])
      store.commit("dashboardTable/UPDATE_FILTERS_DATA", [
        {
          filter: "by_teachers",
          value: []
        }
      ])
      store.commit("dashboardTable/UPDATE_FILTERS_DATA", [
        {
          filter: "by_rooms",
          value: []
        }
      ])
      store.commit("dashboardTable/SET_DASHBOARD_UPDATES", true)
      state.isLoading = true
      store.dispatch("dashboardTable/getDashboardPassesData").then(() => {
        state.isLoading = false
      })
    }

    const setPerPage = (option) => {
      if (option) {
        state.isLazyLoadingMode = option.label === "All entries"
        state.pagination.per_page = option
      }
    }

    const setActivePage = () => {
      helpers.scrollToTop()
    }

    const setActiveRow = (value, index, column, event) => {
      const path = event.path || (event.composedPath && event.composedPath())
      if (state.isBasicView) {
        let isModal = false
        if (path) {
          path.forEach((element) => {
            const classes = element.className
            if (classes && classes == "no-target") {
              isModal = true
            }
          })
        }

        const targetClasses = event.target.classList
        if (!targetClasses.contains("no-target") && !isModal) {
          const dashboardTable = document.getElementById("dashboardTable")
          const rows = dashboardTable.getElementsByTagName("tr")
          for (let i = 0; i < rows.length; i++) {
            const row = rows[i]
            row.classList.remove("active")
          }
          if (
            state.activeTableRow &&
            state.activeTableRow.item.id === value.id
          ) {
            state.activeTableRow = { item: { id: null }, index: null }
          } else {
            state.activeTableRow = { item: value, index }
            if (rows[index + 1]) {
              rows[index + 1].classList.add("active")
            }
          }
        }
      }
    }

    const clearActiveRow = () => {
      const dashboardTable = document.getElementById("dashboardTable")
      const rows = dashboardTable.getElementsByTagName("tr")
      for (let i = 0; i < rows.length; i++) {
        const row = rows[i]
        row.classList.remove("active")
      }
    }

    const sortByColumn = (column) => {
      const columnName = state.columnSearchKeys[column.column]
        ? state.columnSearchKeys[column.column].sortBy
        : column.column
      store.commit("dashboardTable/UPDATE_FILTERS_DATA", [
        {
          filter: "sort",
          value: `${columnName}:${column.asc ? "asc" : "desc"}`
        }
      ])
    }

    const updateSearchQuery = (value) => {
      store.commit("dashboardTable/UPDATE_FILTERS_DATA", {
        filter: "search_query",
        value: value != "" ? value : null
      })
      store.commit("dashboardTable/UPDATE_FILTERS_DATA", [
        {
          filter: "global_search",
          value: 1
        }
      ])
    }

    const searchByQuery = (value) => {
      if (window.searchByQueryTimeout) {
        clearTimeout(window.searchByQueryTimeout)
      }

      window.searchByQueryTimeout = setTimeout(() => {
        if (isAppoinmentMode.value) {
          store.commit("adultAptPass/SET_SEARCH_QUERY", value)
          state.isLoading = true
          updateSearchQuery(value)
          store.dispatch("adultAptPass/getAppointmentPasses").then(() => {
            setTimeout(() => {
              state.isLoading = false
            }, 500)
          })
        } else {
          updateSearchQuery(value)
          state.isLoading = true
          store.dispatch("dashboardTable/getDashboardPassesData").then(() => {
            setTimeout(() => {
              state.isLoading = false
            }, 500)
          })
        }
        if (isActiveDashboardUpdate.value == true) {
          store.commit("dashboardTable/SET_DASHBOARD_UPDATES", false)
        }
      }, 500)
    }

    const getPassStatus = (pass) => {
      if (pass.badge_flags) {
        if (pass.badge_flags.active && !pass.badge_flags.min_time) {
          return { label: "Active", class: "active", color: "#32ba7c" }
        }
        if (pass.badge_flags.ended) {
          return { label: "Ended", class: "ended" }
        }
        if (pass.badge_flags.system_completed) {
          return { label: "System Ended", class: "system-completed" }
        }
        if (pass.badge_flags.min_time) {
          return {
            label: passesTimeSettings.value
              ? helpers.formatDate(
                  passesTimeSettings.value.min_time,
                  "hh:mm:ss",
                  "m"
                ) + "+ MIN"
              : "Min time",
            class: "min-time",
            color: "#fff200"
          }
        }
        if (pass.badge_flags.extended) {
          return { label: "Extended", class: "extended" }
        }
        if (pass.badge_flags.missed) {
          return { label: "Missed", class: "missed", color: "#00a193" }
        }
        if (pass.badge_flags.canceled) {
          return { label: "Canceled", class: "canceled", color: "#7278e0" }
        }
      }
      return ""
    }

    const advancedFilter = () => {
      store.commit("dashboardTable/SET_DASHBOARD_UPDATES", false)
      state.isLoading = true
      store.dispatch("dashboardTable/getDashboardPassesData").then(() => {
        state.isLoading = false
      })
    }

    const resumeDashboardUpdate = () => {
      store.commit("dashboardTable/SET_DASHBOARD_UPDATES", true)
      if (isAppoinmentMode.value) {
        store.dispatch("adultAptPass/getAppointmentPasses")
      } else {
        store.dispatch("dashboardTable/getDashboardPassesData")
      }
    }

    return {
      state,
      periods,
      editablePass,
      passesTimeSettings,
      areAppointmentPassesLoading,
      currentUser,
      filterData,
      aptFilterData,
      dashboardPasses,
      isActiveDashboardUpdate,
      activeSchoolHasAppoitmentPasses,
      isUserInTransparency,
      stats,
      activePassType,
      activePassState,
      school,
      isAppoinmentMode,
      activeDataTableFields,
      exportAsCSV,
      clearFilter,
      setPerPage,
      setActivePage,
      setActiveRow,
      clearActiveRow,
      sortByColumn,
      searchByQuery,
      getPassStatus,
      advancedFilter,
      resumeDashboardUpdate
    }
  }
}
</script>
